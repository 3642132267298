.pAbout{
    background-image: url('../img/banner/about.jpg');
}

.pPrinting{
    background-image: url('../img/banner/printing.jpg');
}

.pMtorder{
    background-image: url('../img/banner/madetoorder.jpg');
}

.aBox{
    margin-bottom: 3rem;

    .well{
        position: relative;
        background: #f7f7f7;
        @include border-radius(10px);

        &:before{
            content: "\f10d";
            font-family: "Font Awesome 5 Free";
            font-weight: 900;
            position: relative;
            top: 0px;
            left: 0px;
            color: $secondary;
            display: block;
            margin-bottom: 10px;
            font-size: 1.6rem;
        }

        p{
            position: relative;

            &:last-child{
                margin-bottom: 0;
            }
        }
    }
}

.spaceText{
    letter-spacing: 5px;
    text-transform: uppercase;
    font-size: 1.8rem;
    text-align: center;

    @include media-breakpoint-down(md){
        font-size: 1rem;
        letter-spacing: 2px;
    }
}