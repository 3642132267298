.sideBar{
    h4{
        text-transform: uppercase;
        font-size: .95rem;
        font-weight: 600;
        color: $secondary;
    }
}

.filterBar{

    @include media-breakpoint-down(md){
        margin-bottom: 20px;
        background: #f7f7f7;
        padding: 15px;
    }
}

.filters{

    margin: 10px 0;

    .btn-filter{
        color: #000;
        font-size: 1.3rem;
        position: relative;
        display: block;
        padding-left: 30px;
        text-align: left;

        @include media-breakpoint-down(md){
            font-size: 1.1rem;
        }

        &:hover, &:focus{
            @include box-shadow(none);
        }

        &:before{
            content: "\f146";
            position: absolute;
            font-family: "Font Awesome 5 Free";
            font-weight: 400;
            left: 0;

        }

        &[aria-expanded="false"]{
            &:before{
                content: "\f0fe";
            }
        }
    }

    .collapse{

    }

    .filterLinks{

        @include media-breakpoint-down(md){
            max-height: 150px;
            overflow-y: auto;
            background: #fff;
            padding: 15px;

            &::-webkit-scrollbar{
                width: 4px;
            }
        }

        a{
            padding: 5px 0;
            margin: 2px 0;
            color: #797979;
            font-size: .85rem;
            display: block;

            &:hover{
                color: $primary;
            }
        }
    }
}