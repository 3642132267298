.slider{
    // background-image: url('../img/slider/2.jpg');
    background-image: url('../img/slider/home.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    padding: 60px 0 40px;
    position: relative;

    &:before{
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background: #0b102326;
    }
    
    .container{
        position: relative;
        z-index: 3;
        min-height: 400px;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;

        @include media-breakpoint-up(xl){
            min-height: 50vh;
        }

        @include media-breakpoint-down(md){
            align-items: center;
            flex-flow: column;
            justify-content: center;
            text-align: center;
        }
    }

    .sliderRow{
        @include media-breakpoint-up(md){
            padding-right: 30px;
        }

        .sCaption{
            text-align: right;
            color: #000;
            margin-bottom: 30px;

            @include media-breakpoint-down(md){
                text-align: center;
            }

            h3{
                font-size: 3rem;
                font-weight: 500;
                text-transform: uppercase;

                @include media-breakpoint-down(md){
                    font-size: 2rem;
                }
            }

            p{
                
            }
        }

        .sImgs{
            display: flex;
            @include media-breakpoint-down(lg){
                display: none;
            }

            .sImg{
                margin-left: 10px;
                min-width: 180px;
                width: 180px;
                // height: 100px;
                position: relative;
                overflow: hidden;
                border: 2px solid #fff;
                @include box-shadow(0 2px 4px rgba(0,0,0,0.2));

                img{

                }
            }
        }
    }

    .sForm{
        padding: 50px 40px;
        text-align: center;
        @include box-shadow(0 2px 4px rgba(0,0,0,0.2));
        position: relative;
        
        @include media-breakpoint-up(md){
            top: 90px;
        }

        @include media-breakpoint-up(xl){
            padding: 70px 60px;
            min-width: 500px;
        }

        @include media-breakpoint-down(lg){
            min-width: 350px;
            padding: 50px 30px;
        }

        @include media-breakpoint-down(sm){
            min-width: auto;
        }

        h4{
            font-size: 1.2rem;
            font-weight: 300;
            letter-spacing: 7px;
            text-transform: uppercase;
            line-height: 1;
            color: #2a3d7b;

            @include media-breakpoint-up(xl){
                font-size: 1.3rem;
            }

            b{
                display: block;
                font-size: 2.2rem;
                font-weight: 500;
                letter-spacing: 0px;

                @include media-breakpoint-up(xl){
                    font-size: 2.5rem;
                }
            }
        }

        p{
            // font-size: .85rem;

            @include media-breakpoint-up(xl){
                font-size: 1rem;
                margin-bottom: 50px;
            }
        }

        .form-group{
            margin-bottom: 10px;
            display: flex;
            align-items: center;

            .form-control{
                border: none;
                border-bottom: 2px solid #e1e1e1;
                @include border-radius(0);

                @include media-breakpoint-up(xl){
                    font-size: 1.5rem;
                }
            }
        }

        .btn{
            @include media-breakpoint-up(xl){
                display: inline-block;
                text-align: center;
            }
        }
    }
}

// Hightlights
.highlights{
    background: #e9e9e9;

    .hBox{
        position: relative;
        padding: 0 10px;

        @include media-breakpoint-down(lg){
            margin-bottom: 30px;
            padding: 0;
        }

        .hBoxImg{
            position: relative;
            overflow: hidden;

            img{
                @include transition(all 0.3s ease-in-out);
                @include transform(scale(1));

            }
        }

        .hBody{
            position: relative;

            .btn{
                font-weight: 600;
                position: absolute;
                bottom: -30px;
                right: -20px;
                background: #fff;
                color: $secondary;
                width: 80%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 20px 20px;
                @include box-shadow(0 2px 4px rgba(0, 0, 0, 0.2));
                @include transition(all 0.3s ease-in-out);

                @include media-breakpoint-down(lg){
                    width: 100%;
                    padding: 10px 15px;
                    bottom: 20px;
                }

                @include media-breakpoint-down(sm){
                    right: 0;
                    bottom: 0px;
                }

                i{
                    position: relative;
                    left: 0;
                    @include transition(all 0.3s ease-in-out);
                }
            }
        }

        &:hover{
            .hBoxImg{

                img{
                    @include transform(scale(1.1));
                }
            }

            .hBody{

                .btn{
                    background: $secondary;
                    color: #fff;
                    right: -15px;

                    @include media-breakpoint-down(sm){
                        right: 0;
                    }
    
                    i{
                        left: 5px
                    }
                }
            }
        }
    }
}


// Info Custom Made
.infoCustom{

    .iImg{
        position: relative;

        @include media-breakpoint-down(md){
            margin-bottom: 30px;
        }

        h5{
            position: absolute;
            z-index: 8;
            right: 20px;
            top: 0;
            font-size: 2rem;
            padding: 30px;
            height: 150px;
            display: flex;
            justify-content: center;
            flex-flow: column;
            background: #f5f5f5;
            color: #162249;
            margin: 0;
            font-weight: 700;
            @include box-shadow(0 2px 4px rgba(0, 0, 0, 0.2));
            @include transition(all 0.3s ease-in-out);

            span{
                font-size: 1rem;
                display: block;
                font-weight: 500;
            }

            @include media-breakpoint-down(lg){
                font-size: 1.5rem;
                padding: 20px;
                height: 100px;

                span{
                    font-size: .8rem;
                }
            }
        }

        .cImg{
            position: relative;
            padding: 50px;
            text-align: center;

            @include media-breakpoint-down(lg){
                padding: 30px;
            }

            img{
                position: relative;
                z-index: 5;
                max-width: 80%;
                margin: 0 auto;
                @include box-shadow(0 2px 4px rgba(0, 0, 0, 0.2));
                @include transform(scale(1));
                @include transition(all 0.3s ease-in-out);
            }

            &:after{
                content: '';
                position: absolute;
                z-index: 3;
                left: 20px;
                bottom: 0;
                height: 200px;
                width: 200px;
                background: $secondary;
                @include box-shadow(0 2px 4px rgba(0, 0, 0, 0.2));
                @include transition(all 0.3s ease-in-out);
            }
        }

        &:hover{
            h5{
                right: 40px;
            }

            .cImg{
                img{
                    @include transform(scale(1.1));
                }
                &:after{
                    left: 40px;
                }
            }
        }
    }

    .iText{
        p{
            // font-size: 1rem;
        }

        h4{
            font-weight: 600;
        }

        a{
            font-size: .9rem;
        }
    }
}


// About
.about{
    background: $secondary;
    margin-top: 40px;
    padding-top: 0;

    .roundHeader{
        text-align: center;
        position: relative;
        top: -60px;

        h3{
            height: 180px;
            width: 180px;
            border: 10px solid #fff;
            background: $secondary;
            @include border-radius(50%);
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 auto;
            text-transform: uppercase;
            @include box-shadow(0 2px 6px #00000073);
        }
    }

    .aboutBox{
        color: #fff;
        text-align: center;

        @include media-breakpoint-down(lg){
            margin-bottom: 30px;
        }

        i{
            font-size: 1.7rem;
            margin-bottom: 20px;
        }

        h4{
            font-weight: 300;
            font-size: 1.4rem;
            white-space: pre-line;
            margin-bottom: 20px;
        }

        p{
            // font-size: .85rem;
            text-align: justify;
            height: 80px;
            overflow: auto;
            padding-right: 10px;
            color: #fff;
            line-height: 1.2;

            &::-webkit-scrollbar{
                width: 2px;
            }

            &::-webkit-scrollbar-thumb {
                background-color: #ccc;
            }

            @include media-breakpoint-up(xl){
                height: 100px;
            }

            @include media-breakpoint-down(md){
                height: auto;
                text-align: center;
            }
        }  
        
        &:hover{
            i, h4{
                color: $warning;
            }

            p::-webkit-scrollbar-thumb {
                background-color: $warning;
            }
        }
    }
}

//@include
.safe{
    background-image: url('../img/home/australia.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-color: #e9e9e9;
    position: relative;

    &:before{
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background: #202e5c94;
    }

    .container{
        position: relative;
    }

    .sHeader{
        text-align: center;
        margin-bottom: 30px;

        h3{
            font-size: 2.2rem;
            font-weight: 500;
            // color: $gray-800;
            color: #fff;

        }
    }

    .sFeatures{
        display: flex;
        align-items: center;
        // flex-flow: column wrap;
        justify-content: center;

        @include media-breakpoint-down(lg){
            display: block;
        }

        .sWhy{
            // background: #202e5c;
            background: #202e5ca6;
            flex: 1;
            height: 100px;
            display: flex;
            align-items: center;
            justify-content: space-evenly;

            @include media-breakpoint-down(lg){
                height: auto;
                margin-bottom: 15px;
            }

            @include media-breakpoint-down(md){
                flex-flow: row wrap;
            }

            .sWhyBox{
                color: #fff;
                text-align: center;
                padding: 18px 15px;
                min-width: 125px;

                i{
                    font-size: 1rem;
                    margin-bottom: 10px;
                }

                p{
                    font-size: .85rem;
                    margin-bottom: 0;
                    color: #fff;
                    // white-space: pre-line;
                }
            }
        }

        .sAct{
            height: 100px;
            display: flex;
            align-items: center;
            background: #fff;
            // background: #d6d6d6;

            @include media-breakpoint-down(lg){
                height: auto;
            }

            div{
                flex: 1;
                text-align: center;
                padding: 10px;
            }

            p{
                font-size: .9rem;
                margin-bottom: 0;

                @include media-breakpoint-down(md){
                    font-size: .8rem;
                }
            }
            span{
                font-size: .75rem;
            }

            img{
                height: 100px;
            }
        }
    }
}