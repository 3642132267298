header{
    height: 85px;
    background: #001970;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 995;
    display: flex;
    align-items: flex-end;

    @include media-breakpoint-up(xl){
        height: 120px
    }
}

.navbar{
    background: $primary;
    width: 100%;
    padding: 0;
    @include box-shadow(0 2px 4px rgba(0,0,0,0.3));

    .container-fluid{
        
        @include media-breakpoint-up(lg){
            align-items: flex-end;
        }
    }

    .navbar-brand{

        img{
            height: 80px;

            @include media-breakpoint-up(xl){
                height: 100px
            }

            @include media-breakpoint-down(md){
                height: 70px;
            }
        }
    }

    .navbar-toggler{
        font-size: 1rem;
        padding: 0.4rem 0.7rem;
        background-color: #ffffff6b;
        border: 1px solid #ffffff66;

        &:focus{
            @include box-shadow(none);
        }

        i{
            color: $primary;
            vertical-align: middle;
        }
    }

    .offcanvas{

        .offcanvas-header{

            .offcanvas-title{
                img{

                }
            }

            .btn-close {

            }
        }

        .offcanvas-body{
            @include media-breakpoint-up(lg){
                align-items: flex-end;
            }

            @include media-breakpoint-down(lg){
                .navbar-nav{
                    text-align: center;

                    .nav-item{

                        &:not(:last-child){
                            .nav-link{
                                border-bottom: 1px solid #e1e1e1;
                            }
                        }
                    }

                    &.specialLinks{
                        flex-flow: row;
                        margin-top: 20px;
                        align-items: center;
                        justify-content: center;

                        .nav-link{
                            border: none !important;
                            padding-right: 1rem;
                            padding-left: 1rem;
                            background: $warning;
                            margin: 0 5px;
                            color: #000;

                            &:hover{
                                background: #253c8d;
                                color: $warning;
                            }
                        }
                    }
                }
            }
            
        }
    }

    .navbar-nav{

        @include media-breakpoint-up(lg){
            height: 70px;
            align-items: center;
        }

        .nav-link{
            text-transform: uppercase;

            @include media-breakpoint-up(lg){
                color: #fff;
                font-size: .9rem;
                font-weight: 400;
                padding: .65rem 1rem;
                letter-spacing: .7px;

                &:hover, &.active{
                    color: $warning;
                }
            }

            @include media-breakpoint-up(xl){
                font-size: 1.1rem;
            }
        }

        &.specialLinks{
            
            @include media-breakpoint-up(lg){
                align-items: flex-end;
                height: 60px;
                margin-left: 15px;
            }

            .nav-link{

                @include media-breakpoint-up(lg){
                    height: 60px;
                    line-height: 60px;
                    background: #253c8d;
                    padding: 0 1rem;

                    &:hover{
                        background: #202e5c;
                    }
                }

                i{

                }
            }

            .special{
                .nav-link{
                    background: $warning;
                    color: #000;
                    font-size: 1rem;
                    font-weight: 600;
                    padding-left: 2.2rem;
                    padding-right: 2.2rem;

                    @include media-breakpoint-up(xl){
                        font-size: 1.2rem;
                    }

                    &:hover{
                        background: #202e5c;
                        color: $warning;
                    }

                    i{
                        margin-right: 5px;
                    }
                }
            }
        }
    }
}