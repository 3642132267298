.shopLanding{

    &.slider{
        padding-top: 30px;

        .container{
            justify-content: center;
            min-height: 250px;

            @include media-breakpoint-up(xl){
                min-height: 500px;
            }
        }
    }

    .sForm{
        h4{
            font-size: 1.5rem;
            letter-spacing: 0px;
            font-weight: 600;
            text-transform: inherit;
            color: #444;

            @include media-breakpoint-up(xl){
                font-size: 1.8rem;
            }
        }
        p{
            font-size: 1.6rem;
            margin-bottom: 5px;

            @include media-breakpoint-up(xl){
                font-size: 1.8rem;
            }
        }
        span{
            font-size: .85rem;
            display: block;
            margin-bottom: 15px;

            @include media-breakpoint-up(xl){
                font-size: 1rem;
            }
        }

        .form-group{
            display: flex;
            align-items: center;

            i{
                margin-right: 15px;
            }

            .form-control{
                border: none;
                border-bottom: 1px solid #e1e1e1;

                @include media-breakpoint-up(xl){
                    font-size: 1.2rem;
                }
            }
        }
    }

    .qContact{
        padding-top: 20px;
    }

    &.highlights{
        h4{
            color: $secondary;
            font-size: 1.7rem;
            text-align: center;
            margin-bottom: 30px;
        }
    }
}

.sSearch{
    padding: 30px 0;

    .searchBar{

        .form-group{
            display: flex;
            align-items: center;

            .form-select{
                font-size: 2rem;
                border: none;
                border-bottom: 1px solid #e1e1e1;
                max-width: 350px;

                @include media-breakpoint-down(md){
                    font-size: 1.5rem;
                }
            }
        }
    }
}

.sAbout{

    .sImg{
        img{
            width: 100%;
        }
        @include media-breakpoint-down(md){
            margin-bottom: 20px;
        }
    }

    .sDesc{
        h4{
            font-weight: 600;
            font-size: 1.8rem;
            margin-bottom: 0;
            @include media-breakpoint-down(md){
                font-size: 1.4rem;
            }
        }

        span{
            display: block;
            font-size: 1.5rem;
            margin-bottom: 20px;
            @include media-breakpoint-down(md){
                font-size: 1.1rem;
            }
        }

        p{
            // font-size: .95rem;
        }

        .sInfo{
            margin-top: 30px;

            .btn{
                
            }
        }
    }
}

.well{
    background: #fbfbfb;
    position: relative;
    padding: 20px;
    margin-bottom: 20px;
}

.sInfo{

    .dInfo{
        
        @include media-breakpoint-up(md){
            border-right: 1px solid #e1e1e1;
            padding-right: 15px;
        }

        .well{
            display: flex;
            align-items: center;

            @include media-breakpoint-down(md){
                display: block;
                text-align: center;
            }

            i{
                font-size: 22px;
                color: $danger;
                text-align: center;
                padding: 30px;
            }

            div{
                flex: 1;
            }

            p{
                margin-bottom: 0;
                color: $danger;
                font-size: 1rem;
            }
        }
    }

    h4{
        font-weight: 600;
        margin-bottom: 20px;
    }

    ul{

        li{
            font-size: 1rem;
            margin-bottom: 10px;

            b{
                color: $primary;
            }
        }
    }

    .cInfo{
        margin: 0 0 30px;

        b{
            font-size: .95rem;
            margin-bottom: 15px;
            display: block;
        }

        p{
            font-size: .95rem;
            margin-bottom: 12px;

            i{
                margin-right: 10px;

                &:not(:first-child){
                    margin-left: 15px;
                }
            }
        }

        .tHours{

            p{
                margin-bottom: 0;

                span{
                    display: inline-block;
                    width: 150px;
                }
            }

            strong{
                font-size: .85rem;
                margin-top: 10px;
                display: block;
            }
        }
    }

}

.quickBar{
    padding: 10px 0;

    .qbRow{
        display: flex;
        align-items: center;
        justify-content: space-between;

        .qbCol{
            padding: 5px;
            display: flex;
            align-items: center;
        }

        .btn{
            color: #878787;
            font-size: 26px;

            @include media-breakpoint-down(md){
                font-size: 20px;
            }

            @include media-breakpoint-down(sm){
                font-size: 16px;
            }

            &:hover{
                color: $primary;
            }
        }

        span{
            color: #878787;
            font-size: 26px;

            @include media-breakpoint-down(md){
                font-size: 20px;
            }

            @include media-breakpoint-down(sm){
                font-size: 16px;
            }
        }
    }

    &.qbCart{
        .btn{
            color: $primary;
            font-size: initial;

            &:hover{
                color: #fff;
            }
        }
    }
}


.zoom {
    display:inline-block;
    position: relative;
}

.zoom img {
    display: block;
}

.zoom img::selection { background-color: transparent; }

// Modal
.modal{

    .modal-header{

        .modal-title{

        }
    }
}


.sizeGuide{

    .modal-header{

        .modal-title{
            display: none;
        }
    }

    h3{
        color: $secondary;
        font-weight: 600;
    }

    p{
        
    }

    h4 {
        font-weight: 600;
        text-transform: uppercase;
    }

    .table{

    }
}