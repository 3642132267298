.mainFooter{
    background: $primary;
    color: #fff;
    padding: 60px 0;
    overflow: hidden;

    .fBox{
        position: relative;

        @include media-breakpoint-down(md){
            margin-bottom: 30px;
        }

        h4{
            position: relative;
            text-transform: uppercase;
            font-size: 1rem;
            font-weight: 500;
            margin-bottom: 15px;
            padding-bottom: 10px;
            color: #fff;

            &:after{
                content: '';
                position: absolute;
                left: 10px;
                bottom: 0;
                right: 0;
                height: 1px;
                background: #576184;
            }
        }

        .fLinks{

            a{
                display: block;
                font-size: .85rem;
                color: #fff;
                position: relative;
                left: 0;
                padding: 5px 0 5px 15px;
                @include transition(all 0.3s ease-in-out);

                @include media-breakpoint-up(xl){
                    font-size: .95rem;
                }

                &:before{
                    content: "\f105";
                    font-family: "Font Awesome 5 Free";
                    font-weight: 900;
                    position: absolute;
                    left: 0;
                    font-size: .75rem;
                    @include transition(all 0.3s ease-in-out);
                }

                &:hover{
                    left: 10px;
                    color: $warning;

                    &:before{
                        left: -10px;
                    }
                }
            }
        }

        &.fContact{
            position: relative;

            &:before{
                position: absolute;
                content: "\f0d7";
                font-family: "Font Awesome 5 Free";
                font-weight: 900;
                top: -95px;
                left: 0;
                font-size: 5rem;
                line-height: 1;

                @include media-breakpoint-down(md){
                    display: none;
                }
            }

            h4{

            }

            a{
                color: #fff;
                display: block;
                font-size: .91rem;

                i{
                    margin-right: 8px;
                    font-size: .8rem;
                }

                &:hover{
                    color: $warning;
                }
            }

            .fSocials{
                a{
                    display: inline-block;
                    margin-right: 8px;

                    i{
                        font-size: 1rem;
                    }
                }
            }

            p{
                font-size: .8rem;
                margin: 15px 0 0;
                font-weight: 300;
                color: #fff;
                font-style: italic;

                @include media-breakpoint-up(xl){
                    font-size: .9rem;
                }
            }
        }
    }
}

.bottomFooter{
    text-align: center;
    padding: 15px 0;
    background: #2c3f82;

    .container{
        @include media-breakpoint-up(md){
            display: flex;
            flex-flow: row-reverse;
            justify-content: space-between;
        }
    }

    img{
        height: 20px;
    }

    p{
        margin: 8px 0 0;
        font-size: .75rem;
        color: #ccc;

        @include media-breakpoint-up(md){
            margin: 0;
        }

        a{
            color: #a2b7ff;
        }
    }
}