.regForm{

    .form-group{
        margin-bottom: 15px;
    }

    h4{
        font-weight: 600;
    }

    p{
        font-size: 16px;
    }
}