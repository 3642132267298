body{
    // font-family: 'Josefin Sans', sans-serif;
    font-family: 'Source Sans Pro', sans-serif;
}

::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

::-webkit-scrollbar-track {
    @include box-shadow(inset 0 0 6px rgba(0,0,0,0.3));
}
 
::-webkit-scrollbar-thumb {
    background-color: #253c8d;
}

img{
    @extend .img-fluid;
}

.container{
    @include media-breakpoint-down(xl){
        max-width: 100%;
    }
}

main{
    padding-top: 85px;

    @include media-breakpoint-up(xl){
        padding-top: 120px;
    }
}

p{
    font-size: 1rem;
    color: #0f0f0f;

    @include media-breakpoint-up(xl){
        font-size: 1.2rem;
    }

    @include media-breakpoint-down(md){
        font-size: .95rem;
    }
}

.block{
    padding: 5rem 0;

    @include media-breakpoint-down(lg){
        padding: 4rem 0;
    }

    @include media-breakpoint-down(sm){
        padding: 3rem 0;
    }
}

.btn{
    font-size: 1rem;
    i{
        margin-right: 10px;
    }

    &.btn-primary{
        &:hover{
            background: $secondary;
            border-color: $secondary;
        }
    }

    &.btn-dark{
        background: #787878;
        border-color: #787878;

        &:hover{
            background: #1c1f23;
            border-color: #1c1f23;
        }
    }   
}

.table{
    @include border-radius(10px);
    overflow: hidden;

    thead{
        background: $secondary;
        // @include border-radius(0 6px 6px 0);

        th{
            border-color: $secondary;
            background: $secondary;
            color: #fff;
        }
    }

    tbody{
        // @include border-radius(0 6px 6px 0);
    }

    th, td{
        font-size: 1rem;
        text-align: center;
        padding: 1rem;
    }
}

.accordion{

    .accordion-item{
        border: none;
    }

    .accordion-header{

        .accordion-button{
            position: relative;
            font-size: 1.5rem;
            font-weight: 600;

            &:before{
                content: "\f068";
                font-family: "Font Awesome 5 Free";
                font-weight: 900;
                position: relative;
                display: inline-block;
                color: $secondary;
                margin-right: 15px;
                font-size: 1.2rem;
            }

            &:after{
                display: none;
            }

            &[aria-expanded="false"]{
                &:before{
                    content: "\f067";
                }
            }

            &:not(.collapsed){
                background: transparent;
            }
        }
    }
}

.list-unstyled{

    li{
        padding-left: 20px;
        position: relative;
        margin-bottom: 8px;

        &:before{
            content: "\f068";
            font-family: "Font Awesome 5 Free";
            font-weight: 900;
            position: absolute;
            left: 0;
            top: 5px;
            display: inline-block;
            color: $secondary;
            font-size: .85rem;
        }

    }
}