@import 'layout/typo';
@import 'bootstrap/bootstrap';
@import 'plugins/font-awesome/fontawesome';
@import 'plugins/font-awesome/brands';
@import 'plugins/font-awesome/regular';
@import 'plugins/font-awesome/solid';
//@import 'plugins/jquery.mCustomScrollbar.min';
//@import 'plugins/hamburgers/hamburgers';
//@import 'plugins/fancybox';
//@import 'plugins/owl.carousel';
@import 'plugins/animate';
@import 'plugins/prefixer';

@import 'layout/default';
@import 'layout/header';
@import 'layout/footer';
@import 'layout/page';

@import 'layout/home';
@import 'layout/college';
@import 'layout/product';
@import 'layout/cart';
@import 'layout/filters';

@import 'layout/about';

@import 'layout/sizing';
@import 'layout/register';

@import 'layout/extras';