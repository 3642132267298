.pSizing{
    background-image: url('../img/banner/sizing.jpg');
}

.sizingBox{

    .table{

        tr{
            td:first-child{
                font-weight: 600;
            }
        }
    }
}

.sizingImg{
    padding-top: 3rem;

    p{
        b{
            font-size: 1.1rem;
            display: inline-block;
            margin-right: 10px;
        }
    }
}