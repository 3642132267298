

.shopCatalogue{

    h2{
        color: #878787;
        margin-bottom: 30px;
    }

    .btns{
        padding-top: 20px;
        text-align: center;

        .btn{
            min-width: 200px;
        }
    }
    
}

.productBox{
    display: block;
    position: relative;
    border: 1px solid #e1e1e1;
    margin-bottom: 30px;
    overflow: hidden;

    .pImg{
        position: relative;
        overflow: hidden;
        text-align: center;
        padding: 20px;

        img{
            margin: 0 auto;
            @include transform(scale(1));
            @include transition(transform ease .3s);
        }
    }
    
    .pDetails{
        padding: 15px;

        h4{
            font-size: .9rem;
            text-transform: uppercase;
            font-weight: 700;
            margin-bottom: 0;
        }

        span{
            font-size: .75rem;
        }
    }

    &:hover{
        .pImg{
            img{
                @include transform(scale(1.1));
            }
        }
    }
}

.product{
    padding: 30px 0;

    .prImage{
        margin-bottom: 20px;

        a{

            img{

            }
        }
    }

    .prImgLabel{
        text-align: center;
        label{
            font-size: 14px;
        }

        @include media-breakpoint-down(md){
            margin-bottom: 30px;
        }
    }

    .prDetails{

        h3{
            font-size: 30px;
            font-weight: 600;
            margin-bottom: 5px;
        }

        span{
            font-size: 18px;
            display: block;
            margin-bottom: 10px;
        }

        p{
            font-size: 15px;
        }

        .prPrice{
            font-size: 22px;
            margin-bottom: 20px;
        }

        .form-group{
            margin-bottom: 15px;
            max-width: 400px;

            label{
                font-weight: 400;
                font-size: 15px;
                margin-bottom: 4px;
            }

            .form-control{

            }
        }

        .prAddtocart{
            margin-bottom: 30px;

            .btn{
                min-width: 180px;
            }
        }

        .prExtras{
            a{
                color: #000;
                font-weight: 600;
            }

            hr{
                margin: 5px 0;
            }
        }
    }   
}

.prModal{

    .prmImg{
        img{

        }
    }

    .prmDetails{

        h4{
            font-weight: 600;
            font-size: 18px;
            margin-bottom: 8px;
        }
        p{

        }

        .prmMore{
            display: flex;
            align-items: center;
            margin-top: 15px;

            .prD{
                margin-right: 15px;
            }
        }
    }
}