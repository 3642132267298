.cart{
    padding: 15px 0;

    .cartTable{
        background: #ebebeb;

        .table{
            @include border-radius(0);

            thead{
                background: initial;

                th{
                    background: initial;
                    color: #000;
                    text-align: left;
                    border-color: transparent;
                }
            }

            td{
                text-align: left;
                border: none;
            }

            .ctName{
                display: flex;
                align-items: center;

                .ctImg{
                    margin-right: 10px;
                    min-width: 60px;

                    img{
                        height: 60px;
                        margin: 0 auto;
                    }
                }

                h4{
                    font-size: 16px;
                    font-weight: 600;
                }
            }
        }
    }

    .cartTotal{
        display: flex;
        align-items: flex-end;
        flex-flow: column;

        .ctGroup{
            display: flex;
            align-items: center;
            padding: 10px 0;

            label, span, .form-control{
                min-width: 180px;
            }

            label{
                font-weight: 600;
            }

            .form-control{
                width: 180px;
            }

            &.total{
                border-top: 1px solid #e1e1e1;
                border-bottom: 1px solid #e1e1e1;
                font-weight: 600;
            }
        }
    }
}