.pSlider{
    position: relative;
    padding-top: 40px;
    background: #dbdcff;

    @include media-breakpoint-down(lg){
        padding: 60px 0;
    }

    &:after{
        content: '';
        position: absolute;
        z-index: 1;
        left: 0;
        right: 0;
        bottom: 0;
        background: $secondary;
        height: 220px;

        @include media-breakpoint-up(xxl){
            height: 243px;
        }

        @include media-breakpoint-down(lg){
            display: none;
        }
        
    }

    .pSliderImg{
        // padding: 20px;
        position: relative;
        z-index: 5;
        padding-bottom: 20px;

        img{
            width: 100%;
            @include box-shadow(0 2px 4px rgba(0,0,0,0.2));
        }
    }

    .sliderArea{
        position: relative;
        z-index: 5;

        .sCaption{
            padding-bottom: 20px;

            h3{
                font-size: 1.8rem;
                font-weight: 500;
                margin-bottom: 20px;

                @include media-breakpoint-down(md){
                    font-size: 1.3rem;
                }
            }

            p{
                // font-size: 1rem;

                @include media-breakpoint-down(md){
                    // font-size: .9rem;
                }
            }

            a{

            }
        }

        .sImgs{
            display: flex;
            margin-top: 30px;
            padding-bottom: 20px;
            
            @include media-breakpoint-down(lg){
                display: none;
            }

            .sImg{
                margin-right: 10px;
                min-width: 180px;
                width: 100%;
                background: #f5f5f5;
                // height: 100px;
                position: relative;
                overflow: hidden;
                border: none;
                @include box-shadow(0 2px 4px rgba(0,0,0,0.2));

                img{
                    margin: 5px;
                }
            }

            p{
                padding: 6px 10px;
                text-align: center;
                font-size: .85rem;
                margin: 0;
                text-transform: uppercase;
                color: #000;
                font-weight: 600;

                span{
                    display: block;
                    font-size: .65rem;
                }
            }
        }
    }
}

//@extend
.cBlock{

    &.bg-light{
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        position: relative;

        &:before{
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            @include media-breakpoint-down(md){
                background: #f3f3f3d9;
            }
        }

        .container{
            position: relative;
            z-index: 3;
        }
    }

    .cText{

        > i{
            margin-bottom: 20px;
            font-size: 1.8rem;
        }

        h4{
            font-size: 1.7rem;
            font-weight: 500;
            margin-bottom: 30px;

            @include media-breakpoint-down(md){
                font-size: 1.4rem;
            }
        }

        p{
            // font-size: 1rem;

            @include media-breakpoint-down(md){
                // font-size: .85rem;
            }
        }

        .btns{
            margin-top: 30px;
        }

        a{
            display: inline-block;
        }
    }
}

//
.cSection{
    

    .qContact{
        text-align: center;

        i{
            margin-bottom: 20px;
            font-size: 1.8rem;
        }

        h4{
            font-size: 1.7rem;
            font-weight: 500;
            margin-bottom: 30px;

            @include media-breakpoint-down(md){
                font-size: 1.4rem;
            }
        }

        p{
            // font-size: 1rem;

            // @include media-breakpoint-down(md){
            //     font-size: .85rem;
            // }
        }

        .btns{
            margin-top: 30px;

            .btn{
                color: $secondary;

                i{
                    font-size: 1rem;
                }
            }
        }
    }
}